@tailwind base;
@tailwind components;

@keyframes fadeOut {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  .animate-fadeOut {
    animation: fadeOut 4000ms ease-in-out infinite;
  }

@tailwind utilities;
